<template>
  <!-- 列车班次列表的条目 -->
  <van-list
    v-model="loading"
    :finished="finished"
    :finished-text="this.list.length==0?'':'没有更多了'"
    @load="onLoad"
  >
    <div
      v-for="(ticketInfo,i) in showlist"
      :key="i"
      @click="chooseTicket(ticketInfo)"
      :class="'itembk '+((!ticketInfo.canBuyNow&&!ticketInfo.displayHasSeat)?'white':'cantbuy')"
    >
      <van-row type="flex" justify="center">
        <van-row class="item-top" type="flex" justify="space-between" align="center">
          <van-col span="6">
            <van-row class="list-time">{{ticketInfo.startTime}}</van-row>
            <van-row class="info-text list-text">{{ticketInfo.fromStationName}}</van-row>
          </van-col>
          <van-col span="6" style="padding-top:6px" class="list-text-big">
            {{ticketInfo.trainCode}}&nbsp;
            <el-tooltip
              class="item"
              effect="dark"
              content="可凭二代身份证直接进出站"
              placement="bottom-start"
              :hide-after="3000"
              @click.prevent.stop
            >
              <van-row
                class="info-text list-text"
                v-if="['C','G','D'].includes(ticketInfo.trainCode.slice(0,1))"
                type="flex"
                justify="center"
                align="center"
                @click.prevent.stop
              >
                <van-icon class="iconfont" class-prefix="icon" name="idCard" />
              </van-row>
            </el-tooltip>
            <van-icon class="iconfont line" class-prefix="icon" name="icon_jipiaodancheng" />
            <van-row class="info-text list-text">{{ticketInfo.runTime}}</van-row>
          </van-col>
          <van-col span="6">
            <!--因为+1天会影响到达时间的对齐显示，所以追加判断-->
            <template v-if="ticketInfo.arriveDays!=='0'">
              <van-row type="flex" justify="top" style="justify-content:center" class="list-time">
                {{ticketInfo.arriveTime}}
                <span
                  style="font-size: 12px;font-weight: normal; font-family: Arial Black, Gadget, sans-serif"
                >{{(!ticketInfo.arriveDay||ticketInfo.arriveDays==="0")?"":'+'+ticketInfo.arriveDays}}</span>
              </van-row>
            </template>
            <!--因为+1天会影响到达时间的对齐显示，所以追加判断-->
            <template v-if="ticketInfo.arriveDays==='0'">
              <van-row class="list-time">{{ticketInfo.arriveTime}}</van-row>
            </template>
            <van-row class="info-text list-text">{{ticketInfo.toStationName}}</van-row>
          </van-col>
          <van-col span="6">
            <van-row>
              <span style="color: red;">￥</span>
              <span style="color: red;font-size: 1.2rem">{{ticketInfo.displayPrice||'- '}}</span>
              <span style="color: #acacac;font-size: .7rem">起</span>
            </van-row>
            <!--服务商标签-->
            <van-row type="flex" justify="center">
              <Logo :corp="ticketInfo.corp" style="padding-top:0.2rem" />
            </van-row>
          </van-col>
        </van-row>
      </van-row>
      <!--可以销售的列车-->
      <template v-if="!ticketInfo.canBuyNow && !ticketInfo.displayHasSeat">
        <van-row type="flex" justify="center">
          <van-row class="item-bottom">
            <template v-for="seat in ticketInfo.displaySeat">
              <van-col v-if="seat.seatNum>10" span="6">{{seat.seatType}}:有票</van-col>
              <van-col v-if=" seat.seatNum>0 && seat.seatNum<=10" span="6">
                {{seat.seatType}}:
                <span style="color: red">{{seat.seatNum}}张</span>
              </van-col>
              <van-col v-if="seat.seatNum==0" span="6">
                <span style="color: #ccc">{{seat.seatType}}:无票</span>
              </van-col>
            </template>
          </van-row>
        </van-row>
      </template>
      <!--全都无票的列车-->
      <template v-else>
        <van-row type="flex" justify="center">
          <van-row type="flex" justify="start" class="item-bottom">
            <van-col
              span="6"
              style="color: darkgray"
            >{{!ticketInfo.canBuyNow && ticketInfo.displayHasSeat?'暂无余票':'暂停销售'}}</van-col>
          </van-row>
        </van-row>
      </template>
    </div>
  </van-list>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "TicketItem",
  props: {
    list: Array
    // filterIDs: Array
  },
  data() {
    return {
      index: 0,
      pagesize: 20,
      showlist: [],
      loading: false,
      finished: false
    };
  },
  components: { Logo },
  methods: {
    chooseTicket(data) {
      this.$emit("childClick", data);
    },
    check(){
      if(this.showlist.length<this.list.length) this.finished=false
    },
    onLoad() {
      setTimeout(() => {
        this.showlist.push(
          ...this.list.slice(this.index, this.index + this.pagesize)
        );
        this.index += this.pagesize;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.showlist.length >= this.list.length) {
          this.finished = true;
        }
      }, 300);
    }
  },
  watch: {
    list() {
      this.showlist = this.list.slice(0, this.index);
      if (this.showlist.length < this.pagesize && this.finished == false)
        this.onLoad();
    }
  }
};
</script>

<style lang="less" src="@/styles/itemCommon.less" scoped></style>
<style lang="less" scoped>
</style>