<template>
  <van-row class="searchTrain">
    <!--头部日历-->
    <van-sticky>
      <div style="background: linear-gradient(to right, rgb(75, 176, 255), rgb(44, 80, 212));">
        <SliderDate
          @changeDate="calendarConfirm"
          :curDate="orderInfo.date"
          @open="show_calendar = true"
          :start="new Date()"
        />
      </div>
    </van-sticky>

    <!-- 列表, 注释掉的部分下拉刷新，即开即用 -->
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
    <van-skeleton
      :row-width="skeletonArr"
      :row="4*7"
      :loading="isLoading"
      :style="isLoading?'height:calc(100vh - 120px)':''"
      class="skeleton-cont"
    >
      <div class="listoutline">
        <List :list="list" :filterIDs="filterIDs" ref="child" @childClick="chooseTicket($event)" />
        <van-empty v-show="!(0 in list)" description="暂无车票，请尝试其他车站。" />
      </div>
    </van-skeleton>
    <!-- </van-pull-refresh> -->

    <div class="gap"></div>

    <!--筛选bar-->
    <van-tabbar style="border:1px solid #eee" v-model="activenav" safe-area-inset-bottom>
      <van-tabbar-item
        name="early"
        class="navicon"
        icon-prefix="icon"
        icon="naozhong"
        replace
        @click="tabbar_item_handle_1()"
      >提早</van-tabbar-item>
      <van-tabbar-item
        name="money"
        class="navicon"
        icon-prefix="icon"
        icon="feiyongbaohan"
        replace
        @click="tabbar_item_handle_2()"
      >省钱</van-tabbar-item>
      <van-tabbar-item
        name="time"
        class="navicon"
        icon-prefix="icon"
        icon="shanguangdengdakai"
        replace
        @click="tabbar_item_handle_3()"
      >省时</van-tabbar-item>
      <van-tabbar-item
        :dot="0 in ticket_filter_activeIds"
        name="filter"
        class="navicon"
        icon-prefix="icon"
        icon="shaixuan"
        replace
        @click="ticket_filter_show = true"
      >筛选</van-tabbar-item>
    </van-tabbar>

    <!--日期选择-->
    <van-calendar
      v-model="show_calendar"
      color="#198BDA"
      :show-confirm="false"
      confirm-disabled-text="请选择出发时间"
      :formatter="calendar_formatter"
      :default-date="new Date(orderInfo.date)"
      position="right"
      :max-date="maxDate"
      :round="false"
      @confirm="calendarConfirm"
    />

    <!--筛选选项卡-->
    <van-action-sheet
      v-model="ticket_filter_show"
      title="筛选"
      @cancel="tabbar_filter_cancel"
      safe-area-inset-bottom
      :close-on-click-overlay="true"
    >
      <div class="tab_bar_filter_content">
        <van-tree-select
          :items="ticket_filter_items"
          :active-id.sync="ticket_filter_activeIds"
          :main-active-index.sync="ticket_filter_activeIndex"
        />
      </div>
      <van-row type="flex" justify="space-around" align="center" style="margin-bottom: 10px">
        <van-button style="width: 100px" plain type="info" @click="tabbar_filter_clear">清空</van-button>
        <van-button style="width: 100px" type="info" @click="tabbar_filter_confirm">确认</van-button>
      </van-row>
    </van-action-sheet>

    <!--加载loading-->
    <!-- <LoadingCircle :show="isLoading" /> -->
  </van-row>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./components/TicketItem";
import { init } from "@/services/list";
import SliderDate from "@/components/SliderDate";
import LoadingCircle from "@/components/LoadingCircle";
import logic from "@/services/commonLogic";
import moment from "moment";
import { ToTop } from "@/services/utils";
import { IsNormal } from "@/services/train";
import { AddDate } from "@/services/date.js";
import SkeletonArr from "@/common/Skeleton.js";
import { TrainServiceProvider } from "@/common/config";
export default {
  name: "SearchTrain",
  components: { List, SliderDate, LoadingCircle },
  computed: {
    ...mapGetters(["orderInfo", "ticketList", "personList"]),
    maxDate: function () {
      return new Date(AddDate(new Date(), 29));
    },
    query: function () {
      return {
        fromCityName: this.orderInfo.trainFromStation,
        toCityName: this.orderInfo.trainToStation,
        fromDate: moment(this.orderInfo.date).format("YYYY-MM-DD"),
        corp: null,
      };
    },
  },
  async mounted() {
    switch (this.orderInfo.trainType) {
      case "高铁 / 动车":
        this.ticket_filter_activeIds.push("类型:动车");
        break;
      case "普通":
        this.ticket_filter_activeIds.push("类型:普通");
    }
    window.setTitle(
      (IsNormal() ? "" : "改签：") +
        this.orderInfo.trainFromStation +
        " - " +
        this.orderInfo.trainToStation
    );
    this.showlist.update("train", this.query);
  },
  data() {
    return {
      activenav: "early", // 底部四个按钮导航 变蓝
      isLoading: true, // 加载动画开关
      list: [], // 用来展示的列表，showlist.list改变会影响list。反之不成立
      showlist: init(), // 缓存对象
      filterIDs: [], //传给子组件的筛选数组
      show_calendar: false,
      calendar_formatter: logic.calendarFormat,
      ticketInfoList: [], //用于展示非停运车票，先存着，也不知道咋展示，就不展示了
      //筛选bar中的详细筛选
      ticket_filter_show: false, // 筛选-开关
      ticket_filter_activeIds: [], // 筛选-选中的id
      ticket_filter_items: [], // 筛选-选项
      ticket_filter_activeIndex: 0, // 筛选-变红的那个
      skeletonArr: SkeletonArr, // 骨架数据
    };
  },
  methods: {
    generateFilterItems() {
      if (0 in this.ticket_filter_items) return;
      let selectList = [
        {
          text: "车次类型",
          children: [
            {
              text: "高铁 / 动车", // 名称
              id: "类型:动车", // id，作为匹配选中状态的标识符
            },
            {
              text: "普通",
              id: "类型:普通",
            },
          ],
        },
      ];
      let start = [],
        serve = [];
      this.showlist.list.forEach((element) => {
        start.push(element.fromStationName);
        serve.push(element.corp);
      });
      selectList.push({
        text: "出发站",
        children: [...new Set(start)].map((item) => {
          return {
            text: item,
            id: "出发站:" + item,
          };
        }),
      });
      selectList.push({
        text: "服务商",
        children: TrainServiceProvider.map((item) => {
          return {
            text: item,
            id: "服务商:" + item,
          };
        }),
      });
      this.ticket_filter_items = selectList;
    },
    // 清空筛选和日期条件（包含子组件）
    clearFilter() {
      this.filterIDs = [];
      this.ticket_filter_activeIds = [];
      this.activenav = "";
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.clearFilter();
        this.showlist.resetList();
        this.showlist.update("train", this.query);
      }, 500);
    },
    // 日历选择日期
    calendarConfirm(date) {
      this.orderInfo.date = date;
      this.$store.dispatch("train/setOrderInfo", this.orderInfo);
      this.show_calendar = false;
    },
    // 下方三个筛选
    tabbar_item_handle_1() {
      this.showlist.sort((a, b) => {
        if ((a.arriveDays || "0") === (b.arriveDays || "0")) {
          let a_time = parseInt(a.startTime.replace(":", ""));
          let b_time = parseInt(b.startTime.replace(":", ""));
          return a_time - b_time;
        } else {
          return a.arriveDays - b.arriveDays;
        }
      }, "train");
      ToTop();
    },
    tabbar_item_handle_2() {
      this.showlist.sort((a, b) => {
        return (
          parseInt(a.displayPrice || a.displaySeat[0].seatPrice) -
          parseInt(b.displayPrice || b.displaySeat[0].seatPrice)
        );
      }, "train");
      ToTop();
    },
    tabbar_item_handle_3() {
      this.showlist.sort((a, b) => {
        return (
          a.runTime.replace(/[^0-9]/gi, "") - b.runTime.replace(/[^0-9]/gi, "")
        );
      }, "train");
      ToTop();
    },

    //筛选filter 取消所有选择
    tabbar_filter_cancel() {
      this.ticket_filter_activeIds = [];
      this.tabbar_filter_confirm();
    },
    //筛选filter 取消所有选择
    tabbar_filter_clear() {
      this.ticket_filter_activeIds = [];
    },
    //筛选filter 确认
    tabbar_filter_confirm() {
      let trainClass = ["G", "C", "D"];
      let corp = [];
      let station = [];
      let type = [];
      this.ticket_filter_activeIds.forEach((e) => {
        if (e.includes("服务商")) corp.push(e.split(":")[1]);
        if (e.includes("出发站")) station.push(e.split(":")[1]);
        if (e.includes("类型")) type.push(e.split(":")[1]);
      });
      let arr = [];
      this.showlist.list.forEach((element) => {
        let f = true;
        if (corp.length > 0) f = f && corp.includes(element.corp);
        if (station.length > 0)
          f = f && station.includes(element.fromStationName);
        if (type.length > 0)
          f =
            f &&
            type.includes(
              trainClass.includes(element.trainCode.slice(0, 1))
                ? "动车"
                : "普通"
            );
        if (f) arr.push(element);
      });
      this.list = arr;
      this.ticket_filter_show = false;
    },

    //❤️❤️核心：选好票啦~！❤️❤️
    chooseTicket(ticketInfo) {
      //如果不能买票，且没座了，就啥也别干
      if (ticketInfo.canBuyNow || ticketInfo.displayHasSeat) {
        return; //啥也不干
      } else if (
        this.$route.name != "searchTrainChange" &&
        ticketInfo.displaySeat.filter((item) => {
          return item.seatNum >= this.personList.length;
        }).length == 0
      ) {
        this.$dialog
          .confirm({
            title: "标题",
            message: "当前余票数量较少，无法保证全部人员有座，是否继续？",
          })
          .then(() => {
            this.$store.dispatch("train/setTicket", ticketInfo);
            this.$router.push(
              this.$route.name.includes("Change")
                ? "orderTrainChange"
                : "/orderTrain"
            );
          });
      } else {
        this.$store.dispatch("train/setTicket", ticketInfo);
        this.$router.push(
          this.$route.name.includes("Change")
            ? "orderTrainChange"
            : "/orderTrain"
        );
      }
    },
  },

  watch: {
    "showlist.check": {
      handler: function () {
        this.generateFilterItems(); // 生成筛选列表，如果需要刷新列表手动将ticket_filter_items=【】
        if (this.$refs.hasOwnProperty("child") && this.$refs.child)
          this.$refs.child.check();
        this.tabbar_filter_confirm();
        if (this.showlist.supplierNum > 0) this.isLoading = false;
        else this.isLoading = true;
      },
    },
    "orderInfo.date": {
      handler: function () {
        this.isLoading = true;
        this.clearFilter();
        this.showlist.update("train", this.query);
      },
    },
  },
};
</script>

<style lang="less" src="../../styles/searchCommon.less" scoped></style>
<style lang="less" scoped>
</style>